 /* eslint-disable */ 
 import React from 'react'
 import Layout from '../../components/Layout'

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

   render() {
     return (
       <Layout>
         <section className="section">
           <div className="container">
             <h1>404 not found</h1>
           </div>
         </section>
       </Layout>
     )
     }
}
 
 